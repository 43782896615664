@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

button, a, .btn {
  font-size: 13px;
}



.h-100 {
  min-height: 100vh;
}

p {
  color: #A0A0A0;
  font-size: 16px !important;
  letter-spacing: 1px !important;
  margin: 10px 0px 10px 0px !important;
  text-align: left;
  font-family: 'Mukta', sans-serif !important;
}

.flex-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.flex-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.link-svg {
  display: block;
  padding: 10px 5px;
}

.link-svg svg path {
  -webkit-transition: .5s ease !important;
  transition: .5s ease !important;
}

.link-svg:hover svg path {
  fill: #8A05BE !important;
}

.border {
  border: 1px solid red !important;
}

.nav-item {
  padding: 0px 10px 0px 10px;
  margin: 10px 5px 10px 5px;
}

.menu-link {
  display: block;
  text-decoration: none;
  font-size: 16px;
  padding: 20px 30px;
  border-radius: 2px;
  color: #A0A0A0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  position: relative;
}

.menu-link:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.menu-link:hover:after {
  width: 100%;
}

.menu-link:after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 0%;
  height: 5px;
  background: linear-gradient(225deg, #8A05BE, #8A05BE);
  border-radius: 2px;
  -webkit-transform: skewX(-30deg);
          transform: skewX(-30deg);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.hamburguer {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.hamburguer:after {
  content: "";
  position: absolute;
  top: 32%;
  left: 12%;
  width: 75%;
  height: 8%;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

.hamburguer:before {
  content: "";
  position: absolute;
  top: 62%;
  left: 42%;
  width: 45%;
  height: 8%;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

#hamburguer:checked ~ label .hamburguer:after {
  top: 46%;
  left: 12%;
  width: 75%;
  height: 8%;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

#hamburguer:checked ~ label .hamburguer:before {
  top: 46%;
  left: 12%;
  width: 75%;
  height: 8%;
  -webkit-transform: rotate(-405deg);
          transform: rotate(-405deg);
}

form {
  margin: 0px 25px 0px 25px;
}

label {
  color: #fff;
  font-weight: 100;
  font-size: 14px;
  text-align: left;
}

input[type=text], input[type=text]:focus,
input[type=number], input[type=number]:focus,
input[type=date], input[type=date]:focus,
input[type=password], input[type=password]:focus,
input[type=email], input[type=email]:focus,
input[type=file], input[type=file]:focus {
  background-color: #353535;
  border: 2px solid #111;
  color: #fff;
}

.textarea, .textarea:focus, .textarea:after {
  background-color: #353535;
  height: 100px;
  border: 2px solid #111;
  color: #fff;
}

.select {
  background-color: #353535;
  border: 2px solid #111;
  color: #fff;
  font-size: 13px;
  height: auto;
  margin: 0;
}

.background {
  /*background-image: url(../img/layout/lion5.jpg);*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: center;
  background-position-y: 10%;
  min-height: 600px;
  width: auto;
  margin: 0;
  padding: 10px 0px 50px 0px;
  overflow: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
}

.bg-switch-dark {
  width: 100vh;
  height: 100vh;
  background: linear-gradient(225deg, #050505, #1a1a1a, #111111, #2b2b2b, #1a1a1a, #101010, #070707);
  background-size: 300% 300%;
  -webkit-animation: bg-switch-dark 15s ease infinite alternate;
          animation: bg-switch-dark 15s ease infinite alternate;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
}

@-webkit-keyframes bg-switch-dark {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes bg-switch-dark {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.bg-switch-cian-green {
  width: auto;
  background: linear-gradient(225deg, #B3FFAB, #00C9FF);
  background-size: 300% 300%;
  -webkit-animation: bg-switch-cian-green 15s ease infinite alternate;
          animation: bg-switch-cian-green 15s ease infinite alternate;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
}

@-webkit-keyframes bg-switch-cian-green {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@keyframes bg-switch-cian-green {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.bgdark {
  background-color: #111111;
  width: auto;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
}

.bggreen {
  background: linear-gradient(225deg, #8A05BE 0%, #8A05BE 100%);
  width: auto;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
}

.bg-transparent {
  background-color: transparent;
}

/* For mobile devices */
@media only screen and (max-width: 1024px) {
  .background {
    /*background-image: url(../img/layout/lion5.jpg);*/
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position-x: center;
    background-position-y: 10%;
    min-height: 600px;
    width: auto;
    margin: 0;
    padding: 10px 0px 50px 0px;
    overflow: hidden;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.75);
  }
}

/*****************************
BUTTON GREEN
******************************/
.btn {
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  padding: 15px 40px !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  -webkit-box-shadow: 0px 4px 25px -10px rgba(0, 0, 0, 0.8) !important;
          box-shadow: 0px 4px 25px -10px rgba(0, 0, 0, 0.8) !important;
  -webkit-transition: 0.2s ease !important;
  transition: 0.2s ease !important;
  font-family: 'Mukta', sans-serif !important;
}

.btn-green {
  background: linear-gradient(225deg, #8A05BE 0%, #8A05BE 100%);
  color: #1A1A1A;
}

.btn-green:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 0px 4px 25px -10px rgba(95, 227, 106, 0.8) !important;
          box-shadow: 0px 4px 25px -10px rgba(95, 227, 106, 0.8) !important;
}

.btn-transparent-green {
  border: 1px solid #8A05BE;
  color: #FFFFFF;
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.btn-transparent-green:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  background: linear-gradient(225deg, #8A05BE 0%, #8A05BE 100%);
  width: 120%;
  height: 0;
  z-index: -1;
  -webkit-transition: .7s ease;
  transition: .7s ease;
}

.btn-transparent-green:hover {
  -webkit-box-shadow: 0px 4px 25px -10px rgba(95, 227, 106, 0.8) !important;
          box-shadow: 0px 4px 25px -10px rgba(95, 227, 106, 0.8) !important;
  border: 1px solid #8A05BE;
  color: #1A1A1A;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.btn-transparent-green:hover:before {
  width: 150%;
  height: 350%;
}

.btn-blue {
  background: linear-gradient(225deg, #2DC7ED 0%, #538BF0 100%);
  color: #1A1A1A;
}

.btn-blue:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-box-shadow: 0px 4px 25px -10px rgba(45, 199, 237, 0.8) !important;
          box-shadow: 0px 4px 25px -10px rgba(45, 199, 237, 0.8) !important;
}

.btn-transparent-blue {
  border: 1px solid #538BF0;
  color: #FFFFFF;
  overflow: hidden;
  position: relative;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.btn-transparent-blue:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  background: linear-gradient(225deg, #2DC7ED 0%, #538BF0 100%);
  width: 120%;
  height: 0;
  z-index: -1;
  -webkit-transition: .7s ease;
  transition: .7s ease;
}

.btn-transparent-blue:hover {
  -webkit-box-shadow: 0px 4px 25px -10px rgba(45, 199, 237, 0.8) !important;
          box-shadow: 0px 4px 25px -10px rgba(45, 199, 237, 0.8) !important;
  border: 1px solid #2DC7ED;
  color: #F9F9F9;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.btn-transparent-blue:hover:before {
  width: 150%;
  height: 350%;
}

.card-bg-hover {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  text-align: center;
  border-radius: 30px;
  margin: 20px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.card-bg-hover:hover {
  /*width: auto;*/
  /*margin: 20px;*/
  -webkit-box-shadow: 0px 0px 20px #000;
          box-shadow: 0px 0px 20px #000;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.card {
  margin: 5px 20px 5px 20px;
  background-color: transparent;
}

.card p {
  color: #eee;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.card p:hover {
  color: #fff;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  text-shadow: 2px 2px 3px black;
}

.card-title {
  color: #fff;
  font-weight: 700;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.card-title:hover {
  color: #fff;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

/*****************************
CARD COLOR
******************************/
.card-color {
  /*background-color: transparent;*/
  /*border: 5px solid transparent;*/
  border: 0;
  height: 0px;
  border-radius: 30px;
  /*background-image: linear-gradient(45deg, #0A9E05 0%, #0CBA07 50%, #0EE909 100%);*/
  /*background-image: linear-gradient(45deg, #B82616 0%, #E84C3D 100%);*/
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.card-color:hover {
  height: 450px;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  /*border: 5px solid #E84C3D;*/
  /*background-color: #E84C3D;*/
  /*background-image: linear-gradient(45deg, #B82616 0%, #E84C3D 100%);*/
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

/*****************************
CARD GRADIENT
******************************/
.card-gradient {
  border: 0px solid #fff;
  height: 0px;
  border-radius: 30px;
  background-image: linear-gradient(45deg, #0A9E05 0%, #0CBA07 50%, #0EE909 100%);
  -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
}

.card-gradient:hover {
  height: 720px;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
  background-image: linear-gradient(45deg, #0A9E05 0%, #0CBA07 50%, #0EE909 100%);
  -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
}

/*
.card-gradient .btn-light{
    -moz-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    
    -webkit-transition:  0.1s cubic-bezier(.25,.7,.53,.85);
    -moz-transition:  0.1s cubic-bezier(.25,.7,.53,.85);
    -o-transition:  0.1s cubic-bezier(.25,.7,.53,.85);
    transition:  0.1s cubic-bezier(.25,.7,.53,.85);
}

.card-gradient:hover .btn-light{
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    
    -webkit-transition:  0.5s cubic-bezier(.25,.7,.53,.85);
    -moz-transition:  0.5s cubic-bezier(.25,.7,.53,.85);
    -o-transition:  0.5s cubic-bezier(.25,.7,.53,.85);
    transition:  0.5s cubic-bezier(.25,.7,.53,.85);
}*/
.card-gradient h4 {
  color: #fff;
  text-shadow: 2px 2px 5px #111;
}

.card-gradient h3 {
  color: #fff;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-shadow: 2px 2px 5px #111;
}

.card-gradient p {
  text-shadow: 2px 2px 5px #111;
}

.card-dark {
  background-color: #202020;
  -webkit-box-shadow: 0px 24px 36px -20px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 24px 36px -20px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
}

/* For mobile devices */
@media only screen and (max-width: 1024px) {
  .card-gradient {
    border: 0px solid #fff;
    height: 700px;
    background-image: none;
    background-color: #111;
    padding: 50px 0px 50px 0px;
    margin: 20px;
    -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
    transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  }
  .card-gradient:hover {
    height: 700px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
    background-image: none;
    background-color: #0CC007;
    -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
    transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  }
  .card-gradient .btn-light {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .card-gradient h3 {
    color: #fff;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 5px #111;
  }
  .card-gradient p {
    text-shadow: 2px 2px 5px #111;
  }
  .card-color {
    border: 0px solid #fff;
    height: 550px;
    background-image: none;
    background-color: #111;
    padding: 50px 0px 50px 0px;
    margin: 20px;
    -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
    transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  }
  .card-color:hover {
    height: 550px;
    -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.75);
    background-image: none;
    background-color: #0CC007;
    -webkit-transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
    transition: 0.6s cubic-bezier(0.25, 0.7, 0.53, 0.85);
  }
}

h1 {
  text-align: left;
  color: #FFFFFF;
  margin: 30px 0px 70px 0px;
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 64px !important;
  line-height: 120%;
  position: relative;
}

h1:after {
  content: '';
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100px;
  height: 10px;
  z-index: -1;
  border-radius: 2px;
  background: linear-gradient(225deg, #8A05BE, #8A05BE);
  -webkit-transform: skewX(-30deg);
          transform: skewX(-30deg);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

h1:hover:after {
  left: 20px;
}

@media screen and (max-width: 980px) {
  h1 {
    font-size: 54px !important;
  }
}

h2 {
  cursor: pointer;
  padding: 0px;
  color: #FFFFFF;
  font-size: 48px !important;
  font-weight: 400 !important;
  line-height: 120%;
  text-align: left;
  margin: 30px 0px 70px 0px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  position: relative;
}

h2:after {
  content: '';
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100px;
  height: 10px;
  z-index: -1;
  border-radius: 2px;
  background: linear-gradient(225deg, #8A05BE, #8A05BE);
  -webkit-transform: skewX(-30deg);
          transform: skewX(-30deg);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

h2:hover:after {
  left: 20px;
}

h3 {
  margin: 5px 0px;
  color: #FFFFFF;
  font-size: 36px !important;
  font-weight: 400 !important;
  line-height: 120%;
  text-align: left;
}

h4 {
  margin: 0px 0px 30px 0px;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px !important;
  font-size: 18px;
  font-weight: 600;
}

h5 {
  margin: 10px 0px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  border: 0;
  background: linear-gradient(225deg, #8A05BE, #8A05BE);
  background-size: 150% 150%;
  background-position: 0% 0%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

h5:hover {
  background-position: 100% 100%;
}

.text-blue {
  background: linear-gradient(225deg, #2DC7ED, #538BF0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-cian {
  background: linear-gradient(225deg, #B1FFAB, #12FFF7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-orange {
  background: linear-gradient(225deg, #FCBD38, #F7931E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-yellow {
  background: linear-gradient(225deg, #E9F725, #9DF224);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-white {
  background: linear-gradient(225deg, #FFFFFF, #F9F9F9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h6 {
  cursor: pointer;
  margin: 20px 0px 20px 0px;
  padding: 15px 0px 15px 30px;
  color: #fafafa;
  text-align: left;
  font-size: 18px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.card-img-top {
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  cursor: pointer;
}

.card-img-top:hover {
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.img-translate {
  cursor: pointer;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.img-translate:hover {
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.img-product-border {
  cursor: pointer;
  border-radius: 5%;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.img-hover {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  text-align: center;
  border-radius: 30px;
  margin: 20px;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.img-hover:hover {
  /*width: auto;*/
  /*margin: 20px;*/
  -webkit-box-shadow: 0px 0px 20px #000;
          box-shadow: 0px 0px 20px #000;
  -webkit-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.ny_anime_logo {
  stroke-width: 1;
  stroke-dasharray: 0 10;
  stroke-dashoffset: 60;
  fill-opacity: 0;
  -webkit-animation: ny_anime_logo 12s ease infinite alternate;
          animation: ny_anime_logo 12s ease infinite alternate;
}

@-webkit-keyframes ny_anime_logo {
  0% {
    stroke-width: 1;
    stroke-dasharray: 0 800;
    stroke-dashoffset: 0;
    fill-opacity: 0;
  }
  85% {
    stroke-width: 1;
    stroke-dasharray: 60 0;
    stroke-dashoffset: 1400;
    fill-opacity: 0;
  }
  100% {
    stroke-dasharray: 60 0;
    stroke-dashoffset: 1400;
    stroke-width: 0;
    fill-opacity: 1;
  }
}

@keyframes ny_anime_logo {
  0% {
    stroke-width: 1;
    stroke-dasharray: 0 800;
    stroke-dashoffset: 0;
    fill-opacity: 0;
  }
  85% {
    stroke-width: 1;
    stroke-dasharray: 60 0;
    stroke-dashoffset: 1400;
    fill-opacity: 0;
  }
  100% {
    stroke-dasharray: 60 0;
    stroke-dashoffset: 1400;
    stroke-width: 0;
    fill-opacity: 1;
  }
}

/* ISO SCRIPT WD */
#isowd #isowd_letter_w {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isowd:hover #isowd_letter_w {
  fill-opacity: 1;
}

#isowd #isowd_letter_d {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isowd:hover #isowd_letter_d {
  fill-opacity: 1;
}

#isowd #isowd_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isowd:hover #isowd_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isowd #isowd_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isowd:hover #isowd_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

/* ISO SCRIPT FE */
#isofe #isofe_letter_f {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isofe:hover #isofe_letter_f {
  fill-opacity: 1;
}

#isofe #isofe_letter_e1 {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isofe:hover #isofe_letter_e1 {
  fill-opacity: 1;
}

#isofe #isofe_letter_e2 {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isofe:hover #isofe_letter_e2 {
  fill-opacity: 1;
}

#isofe #isofe_letter_e3 {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isofe:hover #isofe_letter_e3 {
  fill-opacity: 1;
}

#isofe #isofe_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isofe:hover #isofe_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isofe #isofe_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isofe:hover #isofe_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

/* ISO SCRIPT BD */
#isobd #isobd_letter_d {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isobd:hover #isobd_letter_d {
  fill-opacity: 1;
}

#isobd #isobd_letter_b1 {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isobd:hover #isobd_letter_b1 {
  fill-opacity: 1;
}

#isobd #isobd_letter_b2 {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isobd:hover #isobd_letter_b2 {
  fill-opacity: 1;
}

#isobd #isobd_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isobd:hover #isobd_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isobd #isobd_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isobd:hover #isobd_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

/* ISO SCRIPT LX */
#isolx #isolx_letter_l {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isolx:hover #isolx_letter_l {
  fill-opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isolx #isolx_letter_x {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isolx:hover #isolx_letter_x {
  fill-opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isolx #isolx_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isolx:hover #isolx_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isolx #isolx_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isolx:hover #isolx_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

/* ISO SCRIPT DZ */
#isodz #isodz_letter_d {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isodz:hover #isodz_letter_d {
  fill-opacity: 1;
}

#isodz #isodz_letter_z {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isodz:hover #isodz_letter_z {
  fill-opacity: 1;
}

#isodz #isodz_letter_ {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isodz:hover #isodz_letter_ {
  fill-opacity: 1;
}

#isodz #isodz_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isodz:hover #isodz_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isodz #isodz_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isodz:hover #isodz_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

/* ISO SCRIPT CA */
#isoca #isoca_letter_c {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isoca:hover #isoca_letter_c {
  fill-opacity: 1;
}

#isoca #isoca_letter_a {
  fill-opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  stroke-dasharray: 220 10;
  -webkit-animation: iso_animation_stroke 5s ease-in-out infinite alternate;
          animation: iso_animation_stroke 5s ease-in-out infinite alternate;
}

#isoca:hover #isoca_letter_a {
  fill-opacity: 1;
}

#isoca #isoca_basecolor {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isoca:hover #isoca_basecolor {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

#isoca #isoca_line {
  stroke-width: 3;
  stroke-dasharray: 120 250;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#isoca:hover #isoca_line {
  stroke-width: 3;
  stroke-dasharray: 120 5;
}

@-webkit-keyframes iso_animation_stroke {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 700;
  }
}

@keyframes iso_animation_stroke {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 700;
  }
}

/* ANIMAÇÕES FIGNY1 */
#figny1_button2 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny1_button2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny1_button1 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny1_button1:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny1_square {
  -webkit-animation: figny1_square 3s ease-in infinite alternate;
          animation: figny1_square 3s ease-in infinite alternate;
}

@-webkit-keyframes figny1_square {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny1_square {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#figny1_line {
  -webkit-animation: figny1_line 1s ease-in infinite;
          animation: figny1_line 1s ease-in infinite;
}

@-webkit-keyframes figny1_line {
  0% {
    stroke-width: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
}

@keyframes figny1_line {
  0% {
    stroke-width: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateX(700px);
            transform: translateX(700px);
  }
}

#figny1_circle {
  -webkit-transform-origin: center;
          transform-origin: center;
  transform-box: fill-box;
  -webkit-animation: figny1_circle 1s ease-in infinite;
          animation: figny1_circle 1s ease-in infinite;
}

@-webkit-keyframes figny1_circle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes figny1_circle {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/***************************/
/* ANIMAÇÕES FIGNY2 */
/***************************/
#figny2_button1 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny2_button1:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny2_button2 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny2_button2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny2_circle_dashed {
  -webkit-animation: figny2_circle_dashed 3s ease-in infinite alternate;
          animation: figny2_circle_dashed 3s ease-in infinite alternate;
}

@-webkit-keyframes figny2_circle_dashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny2_circle_dashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#figny2_line_2 {
  -webkit-animation: figny2_line_2 1s ease-in infinite;
          animation: figny2_line_2 1s ease-in infinite;
}

@-webkit-keyframes figny2_line_2 {
  0% {
    stroke-width: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
}

@keyframes figny2_line_2 {
  0% {
    stroke-width: 0;
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateY(-600px);
            transform: translateY(-600px);
  }
}

/***************************/
/* ANIMAÇÕES FIGNY3 */
/***************************/
/*#figny3_frame3:hover{
      transform: translateY(-10px);
      transition: .5s ease;
  }
  
  #figny3_frame3{
      transform: translateY(0px);
      transition: .5s ease;
  }*/
#figny3_linedashed {
  -webkit-animation: figny3_linedashed 3s ease-in infinite alternate;
          animation: figny3_linedashed 3s ease-in infinite alternate;
}

@-webkit-keyframes figny3_linedashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny3_linedashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#figny3_text1frame2 {
  -webkit-animation: figny3_text1frame2 2s ease infinite alternate;
          animation: figny3_text1frame2 2s ease infinite alternate;
}

@-webkit-keyframes figny3_text1frame2 {
  from {
    height: 50px;
  }
  to {
    height: 250px;
  }
}

@keyframes figny3_text1frame2 {
  from {
    height: 50px;
  }
  to {
    height: 250px;
  }
}

#figny3_text2frame2 {
  -webkit-animation: figny3_text2frame2 5s ease infinite alternate;
          animation: figny3_text2frame2 5s ease infinite alternate;
}

@-webkit-keyframes figny3_text2frame2 {
  from {
    height: 70px;
  }
  to {
    height: 150px;
  }
}

@keyframes figny3_text2frame2 {
  from {
    height: 70px;
  }
  to {
    height: 150px;
  }
}

#figny3_text1frame3 {
  -webkit-animation: figny3_text1frame3 5s ease infinite alternate;
          animation: figny3_text1frame3 5s ease infinite alternate;
}

@-webkit-keyframes figny3_text1frame3 {
  from {
    height: 70px;
  }
  to {
    height: 150px;
  }
}

@keyframes figny3_text1frame3 {
  from {
    height: 70px;
  }
  to {
    height: 150px;
  }
}

#figny3_text2frame3 {
  -webkit-animation: figny3_text2frame3 2s ease infinite alternate;
          animation: figny3_text2frame3 2s ease infinite alternate;
}

@-webkit-keyframes figny3_text2frame3 {
  from {
    height: 50px;
  }
  to {
    height: 320px;
  }
}

@keyframes figny3_text2frame3 {
  from {
    height: 50px;
  }
  to {
    height: 320px;
  }
}

#figny3_buttonframe2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_buttonframe2 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_circleframe3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_circleframe3 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item1frame3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item1frame3 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item2frame3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item2frame3 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item3frame3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny3_item3frame3 {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

/***************************/
/* ANIMAÇÕES FIGNY4 */
/***************************/
#figny4_circle:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_circle {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_rect:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_rect {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_triangle:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_triangle {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid1:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid1 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid2 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_grid3 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny4_line1 {
  -webkit-animation: figny4_line1 3s ease-in infinite alternate;
          animation: figny4_line1 3s ease-in infinite alternate;
}

@-webkit-keyframes figny4_line1 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny4_line1 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#figny4_line2 {
  -webkit-animation: figny4_line2 3s ease-in infinite alternate;
          animation: figny4_line2 3s ease-in infinite alternate;
}

@-webkit-keyframes figny4_line2 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny4_line2 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

/* ANIMAÇÕES FIGNY6 */
#figny6:hover #figny6_line1 {
  stroke-dasharray: 500 20;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6 #figny6_line1 {
  stroke-dasharray: 50 500;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6:hover #figny6_line2 {
  stroke-dasharray: 500 20;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6 #figny6_line2 {
  stroke-dasharray: 50 500;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6:hover #figny6_line3 {
  stroke-dasharray: 500 20;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6 #figny6_line3 {
  stroke-dasharray: 50 500;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6:hover #figny6_prototipagem {
  opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6 #figny6_prototipagem {
  opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6:hover #figny6_front_end {
  opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6 #figny6_front_end {
  opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6:hover #figny6_css3 {
  opacity: 1;
}

#figny6 #figny6_css3 {
  opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_figma:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_figma {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_vscode2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_vscode2 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_vscode3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_vscode3 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_bootstrap4:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_bootstrap4 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_html5:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_html5 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_css3:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_css3 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_css3-2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_css3-2 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_js:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_js {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_js2:hover {
  cursor: pointer;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_js2 {
  cursor: pointer;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#figny6_linedashed {
  -webkit-animation: figny6_linedashed 3s ease-in infinite alternate;
          animation: figny6_linedashed 3s ease-in infinite alternate;
}

@-webkit-keyframes figny6_linedashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes figny6_linedashed {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

/* ANIMAÇÃO EU */
#euny_circle1 {
  -webkit-animation: euny_circle1 3s ease-in infinite alternate;
          animation: euny_circle1 3s ease-in infinite alternate;
}

@-webkit-keyframes euny_circle1 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes euny_circle1 {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#euny_square {
  -webkit-animation: euny_square 3s ease-in infinite alternate;
          animation: euny_square 3s ease-in infinite alternate;
}

@-webkit-keyframes euny_square {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

@keyframes euny_square {
  from {
    stroke-dasharray: 120 10;
  }
  to {
    stroke-dasharray: 800 20;
  }
}

#euny_line {
  -webkit-animation: euny_line 1s linear infinite;
          animation: euny_line 1s linear infinite;
}

@-webkit-keyframes euny_line {
  0% {
    stroke-width: 0;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
}

@keyframes euny_line {
  0% {
    stroke-width: 0;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
  50% {
    stroke-width: 3;
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translateX(400px);
            transform: translateX(400px);
  }
}

#euny:hover #euny_line2 {
  cursor: pointer;
  stroke-dasharray: 500 10;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#euny #euny_line2 {
  stroke-dasharray: 100 500;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#euny:hover #euny_nome {
  opacity: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#euny #euny_nome {
  opacity: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

/* ANIMAÇÃO NY TECH */
.nytech_line {
  /*stroke-dasharray: 200 5;*/
  -webkit-animation: nytech_line 2.5s ease-in-out infinite;
          animation: nytech_line 2.5s ease-in-out infinite;
}

@-webkit-keyframes nytech_line {
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 500 20;
  }
  0% {
    stroke-dashoffset: 500;
    stroke-dasharray: 500 30;
  }
}

@keyframes nytech_line {
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 500 20;
  }
  0% {
    stroke-dashoffset: 500;
    stroke-dasharray: 500 30;
  }
}

/*ANIMAÇÃO NY E DISPOSITIVOS*/
@media only screen and (max-width: 460px) {
  h1 {
    font-size: 24px;
  }
  .p_home {
    display: none;
  }
}

#figny7_device {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny7_device 12s ease infinite alternate;
          animation: figny7_device 12s ease infinite alternate;
}

@-webkit-keyframes figny7_device {
  0%,
  20% {
    /*ny*/
    opacity: 0;
    -webkit-transform: translate(0px, 0px) rotate(5deg);
            transform: translate(0px, 0px) rotate(5deg);
  }
  25% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(-5deg);
            transform: translateX(0px) translateY(0px) rotate(-5deg);
  }
  45% {
    opacity: 1;
    width: 180px;
    height: 400px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(5deg);
            transform: translateX(0px) translateY(0px) rotate(5deg);
  }
  47% {
    opacity: 0;
  }
  50% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    -webkit-transform: translateX(-110px) translateY(-70px) rotate(5deg);
            transform: translateX(-110px) translateY(-70px) rotate(5deg);
  }
  70% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    -webkit-transform: translateX(-110px) translateY(-70px) rotate(-5deg);
            transform: translateX(-110px) translateY(-70px) rotate(-5deg);
  }
  73% {
    opacity: 0;
  }
  75% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    -webkit-transform: translateX(-360px) translateY(-50px) rotate(-3deg);
            transform: translateX(-360px) translateY(-50px) rotate(-3deg);
  }
  100% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    -webkit-transform: translateX(-360px) translateY(-50px) rotate(3deg);
            transform: translateX(-360px) translateY(-50px) rotate(3deg);
  }
}

@keyframes figny7_device {
  0%,
  20% {
    /*ny*/
    opacity: 0;
    -webkit-transform: translate(0px, 0px) rotate(5deg);
            transform: translate(0px, 0px) rotate(5deg);
  }
  25% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(-5deg);
            transform: translateX(0px) translateY(0px) rotate(-5deg);
  }
  45% {
    opacity: 1;
    width: 180px;
    height: 400px;
    -webkit-transform: translateX(0px) translateY(0px) rotate(5deg);
            transform: translateX(0px) translateY(0px) rotate(5deg);
  }
  47% {
    opacity: 0;
  }
  50% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    -webkit-transform: translateX(-110px) translateY(-70px) rotate(5deg);
            transform: translateX(-110px) translateY(-70px) rotate(5deg);
  }
  70% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    -webkit-transform: translateX(-110px) translateY(-70px) rotate(-5deg);
            transform: translateX(-110px) translateY(-70px) rotate(-5deg);
  }
  73% {
    opacity: 0;
  }
  75% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    -webkit-transform: translateX(-360px) translateY(-50px) rotate(-3deg);
            transform: translateX(-360px) translateY(-50px) rotate(-3deg);
  }
  100% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    -webkit-transform: translateX(-360px) translateY(-50px) rotate(3deg);
            transform: translateX(-360px) translateY(-50px) rotate(3deg);
  }
}

#figny7_device_border {
  /*mobile*/
  width: 180px;
  height: 400px;
  -webkit-transform: translateX(0px) translateY(0px);
          transform: translateX(0px) translateY(0px);
  /*tablet*/
  width: 380px;
  height: 512px;
  -webkit-transform: translateX(-110px) translateY(-70px);
          transform: translateX(-110px) translateY(-70px);
  /*desktop*/
  width: 896px;
  height: 500px;
  -webkit-transform: translateX(-360px) translateY(-50px);
          transform: translateX(-360px) translateY(-50px);
  -webkit-animation: figny7_device_border 12s ease infinite alternate;
          animation: figny7_device_border 12s ease infinite alternate;
}

@-webkit-keyframes figny7_device_border {
  0% {
    /*ny*/
    opacity: 0;
    stroke-dasharray: 500 1000;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  20% {
    /*ny*/
    opacity: 0;
    stroke-dasharray: 500 1000;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  23% {
    opacity: 0;
  }
  25% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    stroke-dasharray: 0 1000;
    stroke-dashoffset: 0;
    -webkit-transform: translateX(0px) translateY(0px);
            transform: translateX(0px) translateY(0px);
  }
  45% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    stroke-dasharray: 300 10;
    stroke-dashoffset: 1000;
    -webkit-transform: translateX(0px) translateY(0px);
            transform: translateX(0px) translateY(0px);
  }
  47% {
    opacity: 0;
  }
  50% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    stroke-dasharray: 0 2000;
    stroke-dashoffset: 1000;
    -webkit-transform: translateX(-110px) translateY(-70px);
            transform: translateX(-110px) translateY(-70px);
  }
  70% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    stroke-dasharray: 1000 10;
    stroke-dashoffset: 2000;
    -webkit-transform: translateX(-110px) translateY(-70px);
            transform: translateX(-110px) translateY(-70px);
  }
  73% {
    opacity: 0;
  }
  75% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    stroke-dasharray: 0 5000;
    stroke-dashoffset: 0;
    -webkit-transform: translateX(-360px) translateY(-50px);
            transform: translateX(-360px) translateY(-50px);
  }
  100% {
    opacity: 1;
    width: 896px;
    height: 500px;
    stroke-dasharray: 2000 10;
    stroke-dashoffset: 3000;
    -webkit-transform: translateX(-360px) translateY(-50px);
            transform: translateX(-360px) translateY(-50px);
  }
}

@keyframes figny7_device_border {
  0% {
    /*ny*/
    opacity: 0;
    stroke-dasharray: 500 1000;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  20% {
    /*ny*/
    opacity: 0;
    stroke-dasharray: 500 1000;
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  23% {
    opacity: 0;
  }
  25% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    stroke-dasharray: 0 1000;
    stroke-dashoffset: 0;
    -webkit-transform: translateX(0px) translateY(0px);
            transform: translateX(0px) translateY(0px);
  }
  45% {
    /*mobile*/
    opacity: 1;
    width: 180px;
    height: 400px;
    stroke-dasharray: 300 10;
    stroke-dashoffset: 1000;
    -webkit-transform: translateX(0px) translateY(0px);
            transform: translateX(0px) translateY(0px);
  }
  47% {
    opacity: 0;
  }
  50% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    stroke-dasharray: 0 2000;
    stroke-dashoffset: 1000;
    -webkit-transform: translateX(-110px) translateY(-70px);
            transform: translateX(-110px) translateY(-70px);
  }
  70% {
    /*tablet*/
    opacity: 1;
    width: 380px;
    height: 512px;
    stroke-dasharray: 1000 10;
    stroke-dashoffset: 2000;
    -webkit-transform: translateX(-110px) translateY(-70px);
            transform: translateX(-110px) translateY(-70px);
  }
  73% {
    opacity: 0;
  }
  75% {
    /*desktop*/
    opacity: 1;
    width: 896px;
    height: 500px;
    stroke-dasharray: 0 5000;
    stroke-dashoffset: 0;
    -webkit-transform: translateX(-360px) translateY(-50px);
            transform: translateX(-360px) translateY(-50px);
  }
  100% {
    opacity: 1;
    width: 896px;
    height: 500px;
    stroke-dasharray: 2000 10;
    stroke-dashoffset: 3000;
    -webkit-transform: translateX(-360px) translateY(-50px);
            transform: translateX(-360px) translateY(-50px);
  }
}

#figny7_device_circle {
  /*mobile*/
  -webkit-transform: scale(1) translateX(0px) translateY(0px);
          transform: scale(1) translateX(0px) translateY(0px);
  /*tablet*/
  -webkit-transform: scale(1) translateX(0px) translateY(50px);
          transform: scale(1) translateX(0px) translateY(50px);
  /*desktop*/
  -webkit-transform: scale(0.4) translateX(750px) translateY(20px);
          transform: scale(0.4) translateX(750px) translateY(20px);
  /*mobile*/
  transform: scale(1) translateX(0px) translateY(0px);
  -webkit-animation: figny7_device_circle 12s ease infinite alternate;
          animation: figny7_device_circle 12s ease infinite alternate;
}

#figny7_device_circle_border {
  -webkit-animation: figny7_device_circle 12s ease infinite alternate;
          animation: figny7_device_circle 12s ease infinite alternate;
}

@-webkit-keyframes figny7_device_circle {
  0%,
  20% {
    /*ny*/
    opacity: 0;
  }
  23% {
    opacity: 0;
  }
  25%,
  45% {
    /*mobile*/
    opacity: 1;
    /*mobile*/
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
            transform: scale(1) translateX(0px) translateY(0px);
  }
  47% {
    opacity: 0;
  }
  50%,
  70% {
    /*tablet*/
    opacity: 1;
    /*tablet*/
    -webkit-transform: scale(1) translateX(0px) translateY(35px);
            transform: scale(1) translateX(0px) translateY(35px);
  }
  73% {
    opacity: 0;
  }
  75%,
  100% {
    /*desktop*/
    opacity: 1;
    /*desktop*/
    -webkit-transform: scale(0.4) translateX(750px) translateY(20px);
            transform: scale(0.4) translateX(750px) translateY(20px);
  }
}

@keyframes figny7_device_circle {
  0%,
  20% {
    /*ny*/
    opacity: 0;
  }
  23% {
    opacity: 0;
  }
  25%,
  45% {
    /*mobile*/
    opacity: 1;
    /*mobile*/
    -webkit-transform: scale(1) translateX(0px) translateY(0px);
            transform: scale(1) translateX(0px) translateY(0px);
  }
  47% {
    opacity: 0;
  }
  50%,
  70% {
    /*tablet*/
    opacity: 1;
    /*tablet*/
    -webkit-transform: scale(1) translateX(0px) translateY(35px);
            transform: scale(1) translateX(0px) translateY(35px);
  }
  73% {
    opacity: 0;
  }
  75%,
  100% {
    /*desktop*/
    opacity: 1;
    /*desktop*/
    -webkit-transform: scale(0.4) translateX(750px) translateY(20px);
            transform: scale(0.4) translateX(750px) translateY(20px);
  }
}

#figny7_letter_n {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny7_letter_opacity 12s ease infinite alternate;
          animation: figny7_letter_opacity 12s ease infinite alternate;
}

#figny7_letter_y {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny7_letter_opacity 12s ease infinite alternate;
          animation: figny7_letter_opacity 12s ease infinite alternate;
}

@-webkit-keyframes figny7_letter_opacity {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    opacity: 1;
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  23%,
  100% {
    opacity: 0;
  }
}

@keyframes figny7_letter_opacity {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    opacity: 1;
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  20% {
    opacity: 1;
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  23%,
  100% {
    opacity: 0;
  }
}

#figny7_letter_n_border {
  -webkit-animation: figny7_letter_border_opacity 12s ease infinite alternate;
          animation: figny7_letter_border_opacity 12s ease infinite alternate;
}

#figny7_letter_y_border {
  -webkit-animation: figny7_letter_border_opacity 12s ease infinite alternate;
          animation: figny7_letter_border_opacity 12s ease infinite alternate;
}

@-webkit-keyframes figny7_letter_border_opacity {
  0%,
  20% {
    opacity: 1;
  }
  23%,
  100% {
    opacity: 0;
  }
}

@keyframes figny7_letter_border_opacity {
  0%,
  20% {
    opacity: 1;
  }
  23%,
  100% {
    opacity: 0;
  }
}

#figny7_circle1 {
  /*mobile*/
  -webkit-transform: translate(-35px, -110px);
          transform: translate(-35px, -110px);
  /*tablet*/
  -webkit-transform: translate(-150px, -80px);
          transform: translate(-150px, -80px);
  /*desktop*/
  -webkit-transform: translate(-400px, -80px);
          transform: translate(-400px, -80px);
  -webkit-animation: figny7_circle1 12s ease infinite alternate;
          animation: figny7_circle1 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle1 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-35px, -110px);
            transform: translate(-35px, -110px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-150px, -80px);
            transform: translate(-150px, -80px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-400px, -80px);
            transform: translate(-400px, -80px);
  }
}

@keyframes figny7_circle1 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-35px, -110px);
            transform: translate(-35px, -110px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-150px, -80px);
            transform: translate(-150px, -80px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-400px, -80px);
            transform: translate(-400px, -80px);
  }
}

#figny7_circle2 {
  /*mobile*/
  -webkit-transform: translate(62px, -110px);
          transform: translate(62px, -110px);
  /*tablet*/
  -webkit-transform: translate(330px, -110px);
          transform: translate(330px, -110px);
  /*desktop*/
  -webkit-transform: translate(-300px, 0px);
          transform: translate(-300px, 0px);
  -webkit-animation: figny7_circle2 12s ease infinite alternate;
          animation: figny7_circle2 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle2 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(62px, -150px);
            transform: translate(62px, -150px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(330px, -110px);
            transform: translate(330px, -110px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-300px, 0px);
            transform: translate(-300px, 0px);
  }
}

@keyframes figny7_circle2 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(62px, -150px);
            transform: translate(62px, -150px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(330px, -110px);
            transform: translate(330px, -110px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-300px, 0px);
            transform: translate(-300px, 0px);
  }
}

#figny7_circle3 {
  /*mobile*/
  -webkit-transform: translate(62px, -110px);
          transform: translate(62px, -110px);
  /*tablet*/
  transform: translate(62px, -110px);
  /*desktop*/
  -webkit-transform: translate(545px, 100px);
          transform: translate(545px, 100px);
  -webkit-animation: figny7_circle3 12s ease infinite alternate;
          animation: figny7_circle3 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle3 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(188px, -40px);
            transform: translate(188px, -40px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-103px, -110px);
            transform: translate(-103px, -110px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(545px, 100px);
            transform: translate(545px, 100px);
  }
}

@keyframes figny7_circle3 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(188px, -40px);
            transform: translate(188px, -40px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-103px, -110px);
            transform: translate(-103px, -110px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(545px, 100px);
            transform: translate(545px, 100px);
  }
}

#figny7_circle4 {
  /*mobile*/
  -webkit-transform: translate(110px, -150px);
          transform: translate(110px, -150px);
  /*tablet*/
  -webkit-transform: translate(200px, -220px);
          transform: translate(200px, -220px);
  /*desktop*/
  -webkit-transform: translate(465px, 0px);
          transform: translate(465px, 0px);
  -webkit-animation: figny7_circle4 12s ease infinite alternate;
          animation: figny7_circle4 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle4 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(110px, -150px);
            transform: translate(110px, -150px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(200px, -220px);
            transform: translate(200px, -220px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(465px, 0px);
            transform: translate(465px, 0px);
  }
}

@keyframes figny7_circle4 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(110px, -150px);
            transform: translate(110px, -150px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(200px, -220px);
            transform: translate(200px, -220px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(465px, 0px);
            transform: translate(465px, 0px);
  }
}

#figny7_circle5 {
  /*mobile*/
  -webkit-transform: translate(-92px, 285px);
          transform: translate(-92px, 285px);
  /*tablet*/
  -webkit-transform: translate(-200px, 325px);
          transform: translate(-200px, 325px);
  /*desktop*/
  -webkit-transform: translate(0px, -165px);
          transform: translate(0px, -165px);
  -webkit-animation: figny7_circle5 12s ease infinite alternate;
          animation: figny7_circle5 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle5 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-92px, 285px);
            transform: translate(-92px, 285px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-200px, 325px);
            transform: translate(-200px, 325px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(0px, -165px);
            transform: translate(0px, -165px);
  }
}

@keyframes figny7_circle5 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-92px, 285px);
            transform: translate(-92px, 285px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-200px, 325px);
            transform: translate(-200px, 325px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(0px, -165px);
            transform: translate(0px, -165px);
  }
}

#figny7_circle6 {
  /*mobile*/
  -webkit-transform: translate(37px, 225px);
          transform: translate(37px, 225px);
  /*tablet*/
  -webkit-transform: translate(130px, 260px);
          transform: translate(130px, 260px);
  /*desktop*/
  -webkit-transform: translate(240px, -220px);
          transform: translate(240px, -220px);
  -webkit-animation: figny7_circle6 12s ease infinite alternate;
          animation: figny7_circle6 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle6 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(37px, 225px);
            transform: translate(37px, 225px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(130px, 260px);
            transform: translate(130px, 260px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(240px, -220px);
            transform: translate(240px, -220px);
  }
}

@keyframes figny7_circle6 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(37px, 225px);
            transform: translate(37px, 225px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(130px, 260px);
            transform: translate(130px, 260px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(240px, -220px);
            transform: translate(240px, -220px);
  }
}

#figny7_circle7 {
  /*mobile*/
  -webkit-transform: translate(65px, 100px);
          transform: translate(65px, 100px);
  /*tablet*/
  -webkit-transform: translate(-225px, 100px);
          transform: translate(-225px, 100px);
  /*desktop*/
  -webkit-transform: translate(-300px, 235px);
          transform: translate(-300px, 235px);
  -webkit-animation: figny7_circle7 12s ease infinite alternate;
          animation: figny7_circle7 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle7 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(65px, 100px);
            transform: translate(65px, 100px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-225px, 100px);
            transform: translate(-225px, 100px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-300px, 235px);
            transform: translate(-300px, 235px);
  }
}

@keyframes figny7_circle7 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(65px, 100px);
            transform: translate(65px, 100px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-225px, 100px);
            transform: translate(-225px, 100px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-300px, 235px);
            transform: translate(-300px, 235px);
  }
}

#figny7_circle8 {
  /*mobile*/
  -webkit-transform: translate(92px, -210px);
          transform: translate(92px, -210px);
  /*tablet*/
  -webkit-transform: translate(0px, 150px);
          transform: translate(0px, 150px);
  /*desktop*/
  -webkit-transform: translate(200px, 160px);
          transform: translate(200px, 160px);
  -webkit-animation: figny7_circle8 12s ease infinite alternate;
          animation: figny7_circle8 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle8 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(92px, -210px);
            transform: translate(92px, -210px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(0px, 150px);
            transform: translate(0px, 150px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(200px, 160px);
            transform: translate(200px, 160px);
  }
}

@keyframes figny7_circle8 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(92px, -210px);
            transform: translate(92px, -210px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(0px, 150px);
            transform: translate(0px, 150px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(200px, 160px);
            transform: translate(200px, 160px);
  }
}

#figny7_circle9 {
  /*mobile*/
  -webkit-transform: translate(-100px, -115px);
          transform: translate(-100px, -115px);
  /*tablet*/
  -webkit-transform: translate(-190px, -185px);
          transform: translate(-190px, -185px);
  /*desktop*/
  -webkit-transform: translate(-350px, -165px);
          transform: translate(-350px, -165px);
  -webkit-animation: figny7_circle9 12s ease infinite alternate;
          animation: figny7_circle9 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle9 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-100px, -115px);
            transform: translate(-100px, -115px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-190px, -185px);
            transform: translate(-190px, -185px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-350px, -165px);
            transform: translate(-350px, -165px);
  }
}

@keyframes figny7_circle9 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-100px, -115px);
            transform: translate(-100px, -115px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-190px, -185px);
            transform: translate(-190px, -185px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-350px, -165px);
            transform: translate(-350px, -165px);
  }
}

#figny7_circle10 {
  /*mobile*/
  -webkit-transform: translate(-130px, 285px);
          transform: translate(-130px, 285px);
  /*tablet*/
  -webkit-transform: translate(-190px, -185px);
          transform: translate(-190px, -185px);
  /*desktop*/
  -webkit-transform: translate(-150px, 335px);
          transform: translate(-150px, 335px);
  -webkit-animation: figny7_circle10 12s ease infinite alternate;
          animation: figny7_circle10 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle10 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-130px, 285px);
            transform: translate(-130px, 285px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-190px, -185px);
            transform: translate(-190px, -185px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-150px, 335px);
            transform: translate(-150px, 335px);
  }
}

@keyframes figny7_circle10 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-130px, 285px);
            transform: translate(-130px, 285px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(-190px, -185px);
            transform: translate(-190px, -185px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(-150px, 335px);
            transform: translate(-150px, 335px);
  }
}

#figny7_circle11 {
  /*mobile*/
  -webkit-transform: translate(-140px, 20px);
          transform: translate(-140px, 20px);
  /*tablet*/
  -webkit-transform: translate(130px, -210px);
          transform: translate(130px, -210px);
  /*desktop*/
  -webkit-transform: translate(395px, -300px);
          transform: translate(395px, -300px);
  -webkit-animation: figny7_circle11 12s ease infinite alternate;
          animation: figny7_circle11 12s ease infinite alternate;
}

@-webkit-keyframes figny7_circle11 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-140px, 20px);
            transform: translate(-140px, 20px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(130px, -210px);
            transform: translate(130px, -210px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(395px, -300px);
            transform: translate(395px, -300px);
  }
}

@keyframes figny7_circle11 {
  0%,
  20% {
    /*ny*/
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px);
  }
  25%,
  45% {
    /*mobile*/
    -webkit-transform: translate(-140px, 20px);
            transform: translate(-140px, 20px);
  }
  50%,
  70% {
    /*tablet*/
    -webkit-transform: translate(130px, -210px);
            transform: translate(130px, -210px);
  }
  75%,
  100% {
    /*desktop*/
    -webkit-transform: translate(395px, -300px);
            transform: translate(395px, -300px);
  }
}

/* ANIMAÇÃO FIGNY8 */
.svg-figny8 #figny8-facebook {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-youtube {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-dribbble {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-instagram {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-behance {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-github {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 #figny8-codepen {
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8 .figny8-line {
  stroke-dasharray: 5 500;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny8:hover .figny8-line {
  stroke-dasharray: 150 10;
}

.svg-figny8:hover #figny8-facebook {
  opacity: 1;
}

.svg-figny8:hover #figny8-youtube {
  opacity: 1;
}

.svg-figny8:hover #figny8-dribbble {
  opacity: 1;
}

.svg-figny8:hover #figny8-instagram {
  opacity: 1;
}

.svg-figny8:hover #figny8-behance {
  opacity: 1;
}

.svg-figny8:hover #figny8-github {
  opacity: 1;
}

.svg-figny8:hover #figny8-codepen {
  opacity: 1;
}

/* ANIMAÇÃO FIGNY9 */
#figny9-cursor {
  transform-box: fill-box;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny9-cursor 7s ease infinite alternate;
          animation: figny9-cursor 7s ease infinite alternate;
}

@-webkit-keyframes figny9-cursor {
  0% {
    -webkit-transform: translate3d(-20px, 0, 0) scale(1);
            transform: translate3d(-20px, 0, 0) scale(1);
  }
  20% {
    -webkit-transform: translate3d(-20px, -150px, 0) scale(1);
            transform: translate3d(-20px, -150px, 0) scale(1);
  }
  30% {
    -webkit-transform: translate3d(-100px, -150px, 0) scale(1);
            transform: translate3d(-100px, -150px, 0) scale(1);
  }
  40% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(1);
            transform: translate3d(-200px, -100px, 0) scale(1);
  }
  45% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(0.9);
            transform: translate3d(-200px, -100px, 0) scale(0.9);
  }
  50% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(1);
            transform: translate3d(-200px, -100px, 0) scale(1);
  }
  85% {
    -webkit-transform: translate3d(-130px, -20px, 0) scale(1);
            transform: translate3d(-130px, -20px, 0) scale(1);
  }
  100% {
    -webkit-transform: translate3d(-50px, 0, 0) scale(1);
            transform: translate3d(-50px, 0, 0) scale(1);
  }
}

@keyframes figny9-cursor {
  0% {
    -webkit-transform: translate3d(-20px, 0, 0) scale(1);
            transform: translate3d(-20px, 0, 0) scale(1);
  }
  20% {
    -webkit-transform: translate3d(-20px, -150px, 0) scale(1);
            transform: translate3d(-20px, -150px, 0) scale(1);
  }
  30% {
    -webkit-transform: translate3d(-100px, -150px, 0) scale(1);
            transform: translate3d(-100px, -150px, 0) scale(1);
  }
  40% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(1);
            transform: translate3d(-200px, -100px, 0) scale(1);
  }
  45% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(0.9);
            transform: translate3d(-200px, -100px, 0) scale(0.9);
  }
  50% {
    -webkit-transform: translate3d(-200px, -100px, 0) scale(1);
            transform: translate3d(-200px, -100px, 0) scale(1);
  }
  85% {
    -webkit-transform: translate3d(-130px, -20px, 0) scale(1);
            transform: translate3d(-130px, -20px, 0) scale(1);
  }
  100% {
    -webkit-transform: translate3d(-50px, 0, 0) scale(1);
            transform: translate3d(-50px, 0, 0) scale(1);
  }
}

#figny9-cursor {
  transform-box: fill-box;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny9-cursor 7s ease infinite alternate;
          animation: figny9-cursor 7s ease infinite alternate;
}

@keyframes figny9-cursor {
  0% {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1);
            transform: translate3d(-100px, 0, 0) scale(1);
  }
  20% {
    -webkit-transform: translate3d(-150px, -150px, 0) scale(1);
            transform: translate3d(-150px, -150px, 0) scale(1);
  }
  30% {
    -webkit-transform: translate3d(-170px, -200px, 0) scale(1);
            transform: translate3d(-170px, -200px, 0) scale(1);
  }
  40% {
    -webkit-transform: translate3d(-250px, -100px, 0) scale(1);
            transform: translate3d(-250px, -100px, 0) scale(1);
  }
  45% {
    -webkit-transform: translate3d(-250px, -100px, 0) scale(0.9);
            transform: translate3d(-250px, -100px, 0) scale(0.9);
  }
  50% {
    -webkit-transform: translate3d(-250px, -100px, 0) scale(1);
            transform: translate3d(-250px, -100px, 0) scale(1);
  }
  100% {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1);
            transform: translate3d(-100px, 0, 0) scale(1);
  }
}

#figny9-box {
  transform-box: fill-box;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation: figny9-box 7s ease infinite alternate;
          animation: figny9-box 7s ease infinite alternate;
}

@-webkit-keyframes figny9-box {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes figny9-box {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.svg-figny9 #figny9-divisor {
  transform-box: fill-box;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.svg-figny9:hover #figny9-divisor {
  -webkit-transform: translate3d(20px, 0, 0);
          transform: translate3d(20px, 0, 0);
}

/*.svg-figny8:hover #figny8-facebook{
    opacity: 1;
  }*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: linear-gradient(225deg, #8A05BE, #5fe46c);
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 4px;
}

.list-group {
  border-radius: 0;
}

.list-group-item {
  text-align: left;
  border: 0px;
  color: #A0A0A0;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 10px 0px 10px 30px;
  margin: 10px 0px 10px 0px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: relative;
  /*&:hover{
        text-align: left;
        padding: 10px 0px 10px 40px;
        border-left: 5px solid;
        border-image: linear-gradient(to bottom, $green-1, $green-2) 1 100%;
        color: $white-1;
    }*/
}

.list-group-item:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0%;
  width: 5px;
  background: linear-gradient(225deg, #8A05BE, #8A05BE);
  -webkit-transform: skewY(-30deg);
          transform: skewY(-30deg);
  border-radius: 2px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.list-group-item:hover:after {
  height: 100%;
}

.list-group-item-blue {
  /*&:hover{
        text-align: left;
        padding: 10px 0px 10px 40px;
        border-left: 5px solid;
        border-image: linear-gradient(to bottom, $green-1, $green-2) 1 100%;
        color: $white-1;
    }*/
}

.list-group-item-blue:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0%;
  width: 5px;
  background: linear-gradient(225deg, #2DC7ED, #538BF0);
  -webkit-transform: skewY(-30deg);
          transform: skewY(-30deg);
  border-radius: 2px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.list-group-item-blue:hover:after {
  height: 100%;
}

.list-group-item a {
  color: #ddd;
  font-size: 16px;
  font-weight: bold;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.list-group-item a:hover {
  color: #fff;
  text-decoration: none;
  text-shadow: 5px 5px 10px #000;
}

:root {
  --cursorX: 20vw;
}

.box-bar {
  width: 100%;
  margin: 50px 0px;
  background-color: #1A1A1A;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.box-bar span {
  color: #2DC7ED;
  text-transform: uppercase;
}

.text-wrapper {
  padding-left: 100px;
}

.text-wrapper h2, .text-wrapper h3 {
  margin: 40px 0px;
  padding: 0;
  text-transform: uppercase;
}

.bar {
  position: absolute;
  left: 10%;
  height: 50%;
  width: 20px;
  border-radius: 10px;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
          box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
  z-index: 99;
  -webkit-animation: fluctuateBar 1s ease-in-out 1s;
          animation: fluctuateBar 1s ease-in-out 1s;
}

@-webkit-keyframes fluctuateBar {
  0% {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  20% {
    -webkit-box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
            box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
  }
  40% {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    -webkit-box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
            box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
  }
}

@keyframes fluctuateBar {
  0% {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  20% {
    -webkit-box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
            box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
  }
  40% {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    -webkit-box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
            box-shadow: 0 0 5px #2DC7ED, 0 0 6px #2DC7ED, 0 0 7px #2DC7ED, 0 0 8px #2DC7ED, 0 0 9px #2DC7ED, 0 0 10px #2DC7ED, 0 0 20px #2DC7ED, 0 0 40px #2DC7ED, 0 0 320px #2DC7ED, 0 0 330px #2DC7ED;
  }
}

/* ANIMAÇÕES FIGS */
#figfp1-cursor {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transform-box: fill-box;
  -webkit-animation: figfp1-cursor 7s ease infinite alternate;
          animation: figfp1-cursor 7s ease infinite alternate;
}

@-webkit-keyframes figfp1-cursor {
  0%, 25% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-100px, -200px, 0);
            transform: translate3d(-100px, -200px, 0);
  }
  75% {
    -webkit-transform: translate3d(-200px, -50px, 0);
            transform: translate3d(-200px, -50px, 0);
  }
  100% {
    -webkit-transform: translate3d(50px, 0px, 0);
            transform: translate3d(50px, 0px, 0);
  }
}

@keyframes figfp1-cursor {
  0%, 25% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(-100px, -200px, 0);
            transform: translate3d(-100px, -200px, 0);
  }
  75% {
    -webkit-transform: translate3d(-200px, -50px, 0);
            transform: translate3d(-200px, -50px, 0);
  }
  100% {
    -webkit-transform: translate3d(50px, 0px, 0);
            transform: translate3d(50px, 0px, 0);
  }
}

/* ANIMAÇÃO FIGFP4 */
#figfp4-line {
  -webkit-animation: figfp4-line 1s linear infinite;
          animation: figfp4-line 1s linear infinite;
}

@-webkit-keyframes figfp4-line {
  0% {
    stroke-width: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
            transform: translate3d(-200px, 0, 0);
  }
  50% {
    stroke-width: 2;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0);
  }
}

@keyframes figfp4-line {
  0% {
    stroke-width: 0;
    -webkit-transform: translate3d(-200px, 0, 0);
            transform: translate3d(-200px, 0, 0);
  }
  50% {
    stroke-width: 2;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  100% {
    stroke-width: 0;
    -webkit-transform: translate3d(200px, 0, 0);
            transform: translate3d(200px, 0, 0);
  }
}

#figfp4-square, #figfp4-circle-2 {
  -webkit-animation: figfp4-square 2s ease infinite alternate;
          animation: figfp4-square 2s ease infinite alternate;
}

@-webkit-keyframes figfp4-square {
  0% {
    stroke-dasharray: 10 20;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 100 5;
    stroke-dashoffset: 500;
  }
}

@keyframes figfp4-square {
  0% {
    stroke-dasharray: 10 20;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 100 5;
    stroke-dashoffset: 500;
  }
}

#figfp4-dash {
  -webkit-animation: figfp4-dash 5s ease infinite alternate;
          animation: figfp4-dash 5s ease infinite alternate;
}

@-webkit-keyframes figfp4-dash {
  0% {
    height: 100px;
  }
  100% {
    height: 325px;
  }
}

@keyframes figfp4-dash {
  0% {
    height: 100px;
  }
  100% {
    height: 325px;
  }
}

#figfp4-circle {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transform-box: fill-box;
  -webkit-animation: figfp4-circle 4s ease-in-out infinite alternate;
          animation: figfp4-circle 4s ease-in-out infinite alternate;
}

@-webkit-keyframes figfp4-circle {
  0% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

@keyframes figfp4-circle {
  0% {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
  }
}

#figfp4-headline {
  -webkit-animation: figfp4-headline 2s ease infinite alternate;
          animation: figfp4-headline 2s ease infinite alternate;
}

@-webkit-keyframes figfp4-headline {
  0% {
    width: 20px;
  }
  100% {
    width: 100px;
  }
}

@keyframes figfp4-headline {
  0% {
    width: 20px;
  }
  100% {
    width: 100px;
  }
}

#figfp4-text1 {
  -webkit-animation: figfp4-text1 2s ease infinite alternate;
          animation: figfp4-text1 2s ease infinite alternate;
}

@-webkit-keyframes figfp4-text1 {
  0% {
    width: 120px;
  }
  100% {
    width: 80px;
  }
}

@keyframes figfp4-text1 {
  0% {
    width: 120px;
  }
  100% {
    width: 80px;
  }
}

#figfp4-text2 {
  -webkit-animation: figfp4-text2 3s ease infinite alternate;
          animation: figfp4-text2 3s ease infinite alternate;
}

@-webkit-keyframes figfp4-text2 {
  0% {
    width: 60px;
  }
  100% {
    width: 150px;
  }
}

@keyframes figfp4-text2 {
  0% {
    width: 60px;
  }
  100% {
    width: 150px;
  }
}

#figfp4-box1, #figfp4-box2, #figfp4-box3, #figfp4-button {
  -webkit-transition: .5s ease;
  transition: .5s ease;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  cursor: pointer;
}

#figfp4-box1:hover, #figfp4-box2:hover, #figfp4-box3:hover, #figfp4-button:hover {
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
}

/* ANIMAÇÃO FIGFP 5 */
#figfp5-progress, #figfp5-play {
  -webkit-transition: .5s ease;
  transition: .5s ease;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  transform-box: fill-box;
  -webkit-transform: scale(1);
          transform: scale(1);
}

#figfp5-progress:hover, #figfp5-play:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

#figfp5-title {
  -webkit-animation: figfp5-title 2s ease infinite alternate;
          animation: figfp5-title 2s ease infinite alternate;
}

@-webkit-keyframes figfp5-title {
  0% {
    width: 20px;
  }
  100% {
    width: 140px;
  }
}

@keyframes figfp5-title {
  0% {
    width: 20px;
  }
  100% {
    width: 140px;
  }
}

#figfp5-text1 {
  -webkit-animation: figfp5-text1 2s ease infinite alternate;
          animation: figfp5-text1 2s ease infinite alternate;
}

@-webkit-keyframes figfp5-text1 {
  0% {
    width: 20px;
  }
  100% {
    width: 80px;
  }
}

@keyframes figfp5-text1 {
  0% {
    width: 20px;
  }
  100% {
    width: 80px;
  }
}

#figfp5-text2 {
  -webkit-animation: figfp5-text2 1s ease infinite alternate;
          animation: figfp5-text2 1s ease infinite alternate;
}

@-webkit-keyframes figfp5-text2 {
  0% {
    width: 20px;
  }
  100% {
    width: 120px;
  }
}

@keyframes figfp5-text2 {
  0% {
    width: 20px;
  }
  100% {
    width: 120px;
  }
}

#bot {
  padding-bottom: 20px;
}
/*# sourceMappingURL=style.css.map */